<template lang="html">
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(handleSubmission)" class="Form">
      <div class="Form__wrapper">
        <h1 class="title-3">Forgotten username</h1>
        <p class="text-xlg text-semi">Provide email address to retrieve username</p>
        <formly-form
          :form="form"
          :model="model"
          :fields="fields"
          tag="div"
          class="Form__group"
        ></formly-form>
      </div>
      <button class="btn btn--primary btn--min">Retrieve Username</button>
    </form>
    <router-link :to="{ name: 'register-player' }" class="clickable nav-link block mt-16 text-md">
      Click here to register
    </router-link>
    <router-link
        :to="{ name: 'login' }"
        class="clickable nav-link block mt-8 text-md"
        >Log in</router-link
      >
    <router-link :to="{ name: 'password-recovery' }" class="clickable nav-link block mt-8 text-md">
      Forgot your password?
    </router-link>
  </ValidationObserver>
</template>

<script>
  export default {
    name: 'ForgotUsername',
    data: function() {
      return {
        model: {
          email: null,
        },
        form: {},
        fields: [
          {
            key: 'email',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'email',
              label: 'Email Address',
              layout: 'half',
            },
          },
        ],
      }
    },
    created() {
      this.$store.commit('requestReset', false)
    },
    methods: {
      handleSubmission() {
        this.$store.dispatch('FORGOTTEN_USERNAME', this.model)
      },
    },
  }
</script>
